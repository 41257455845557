<template>
    <div class="SettleDetail">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-row>
                <el-col :span="4">结算机构: {{ form.deptName }}</el-col>
                <el-col :span="4">结算月份: {{ form.year + '.' + form.month }}</el-col>
                <el-col :span="4">创建人: {{ form.creator }}</el-col>
                <el-col :span="8">创建时间: {{ form.createTime }}</el-col>
            </el-row>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>

            <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.report.settle.review'" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column prop="goodsName" label="商品名称" width="200" fixed="left" />
                <el-table-column prop="skuCode" label="sku编码" width="180" />
                <el-table-column prop="goodsCode" label="商品编码" width="180" />
                <el-table-column prop="planCost" label="计划成本" width="120" :formatter="mFour" />

                <el-table-column label="期初" header-align="center">
                    <el-table-column prop="startCount" label="期初数量" width="120" :formatter="mCount" />
                    <el-table-column prop="originalStartCost" label="原始期初成本金额" width="180" :formatter="mFour" />
                    <el-table-column
                        prop="originalStartCostDiff"
                        label="原始期初成本差异"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column prop="planCostDiff" label="计划成本调整差异" width="160" :formatter="mFour" />
                    <el-table-column prop="startCost" label="期初成本金额" width="160" :formatter="mFour" />
                    <el-table-column prop="startCostDiff" label="期初成本差异" width="160" :formatter="mFour" />
                </el-table-column>
                <el-table-column label="进项" header-align="center">
                    <el-table-column prop="purchaseCount" label="采购数量" width="120" :formatter="mCount" />
                    <el-table-column prop="purchaseCost" label="采购成本金额" width="160" :formatter="mFour" />
                    <el-table-column prop="purchaseCostDiff" label="采购成本差异" width="160" :formatter="mFour" />
                    <el-table-column prop="purchasePrice" label="采购金额" width="120" :formatter="mFour" />
                    <el-table-column prop="purchaseRefundCount" label="采购退货数量" width="160" :formatter="mCount" />
                    <el-table-column
                        prop="purchaseRefundCost"
                        label="采购退货成本金额"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column
                        prop="purchaseRefundCostDiff"
                        label="采购退货成本差异"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column prop="purchaseRefundPrice" label="采购退货金额" width="160" :formatter="mFour" />
                    <el-table-column prop="allocateInCount" label="调拨入库数量" width="160" :formatter="mCount" />
                    <el-table-column prop="allocateInCost" label="调拨入库成本金额" width="160" :formatter="mFour" />
                    <el-table-column
                        prop="allocateInCostDiff"
                        label="调拨入库成本差异"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column prop="allocateInPrice" label="调拨入库金额" width="180" :formatter="mFour" />
                    <el-table-column
                        prop="sysPurchaseCount"
                        label="系统初始化入库数量"
                        width="160"
                        :formatter="mCount"
                    />
                    <el-table-column
                        prop="sysPurchaseCost"
                        label="系统初始化入库成本金额"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column
                        prop="sysPurchaseCostDiff"
                        label="系统初始化入库成本差异"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column
                        prop="sysPurchasePrice"
                        label="系统初始化入库金额"
                        width="160"
                        :formatter="mFour"
                    />
                    <el-table-column
                        prop="sysPurchaseOutCount"
                        label="系统初始化出库数量"
                        width="160"
                        :formatter="mCount"
                    />
                    <el-table-column
                        prop="sysPurchaseOutCost"
                        label="系统初始化出库成本金额"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column
                        prop="sysPurchaseOutCostDiff"
                        label="系统初始化出库成本差异"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column prop="onlineShoppingOrderCount" label="电商销售数量" width="180" />
                    <el-table-column
                        prop="onlineShoppingOrderCost"
                        label="电商销售成本金额"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column
                        prop="onlineShoppingOrderPrice"
                        label="电商销售金额"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column prop="onlineShoppingRefundCount" label="电商退款数量" width="180" />
                    <el-table-column
                        prop="onlineShoppingRefundCost"
                        label="电商退款成本金额"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column
                        prop="onlineShoppingRefundPrice"
                        label="电商退款金额"
                        width="180"
                        :formatter="mFour"
                    />
                    <el-table-column
                        prop="sysPurchaseOutPrice"
                        label="系统初始化出库金额"
                        width="160"
                        :formatter="mFour"
                    />
                </el-table-column>
                <el-table-column label="销项" header-align="center">
                    <el-table-column prop="saleCount" label="销售数量" width="120" :formatter="mCount" />
                    <el-table-column prop="saleCost" label="销售成本金额" width="120" :formatter="mFour" />
                    <el-table-column prop="salePrice" label="销售金额" width="120" :formatter="mFour" />
                    <el-table-column prop="saleRefundCount" label="销售退货数量" width="120" :formatter="mCount" />
                    <el-table-column prop="saleRefundCost" label="销售退货成本金额" width="120" :formatter="mFour" />
                    <el-table-column prop="saleRefundPrice" label="销售退货金额" width="120" :formatter="mFour" />
                    <el-table-column prop="lossCount" label="报损数量" width="120" :formatter="mCount" />
                    <el-table-column prop="lossCost" label="报损成本金额" width="120" :formatter="mFour" />
                    <el-table-column prop="surplusCount" label="报溢数量" width="120" :formatter="mCount" />
                    <el-table-column prop="surplusCost" label="报溢成本金额" width="120" :formatter="mFour" />
                    <el-table-column prop="allocateOutCount" label="调拨出库数量" width="120" :formatter="mCount" />
                    <el-table-column prop="allocateOutCost" label="调拨出库成本金额" width="120" :formatter="mFour" />
                    <el-table-column prop="allocateOutPrice" label="调拨出库金额" width="120" :formatter="mFour" />
                    <el-table-column prop="receiveCount" label="内购数量" width="120" :formatter="mCount" />
                    <el-table-column prop="receiveCost" label="内购成本金额" width="120" :formatter="mFour" />
                    <el-table-column prop="receivePrice" label="内购金额" width="120" :formatter="mFour" />
                </el-table-column>
                <el-table-column label="汇总" header-align="center">
                    <el-table-column prop="inCountSummary" label="进项数量" width="120" :formatter="mCount" />
                    <el-table-column prop="inCostSummary" label="进项成本金额" width="120" :formatter="mFour" />
                    <el-table-column prop="inCostDiffSummary" label="进项成本差异" width="120" :formatter="mFour" />
                    <el-table-column prop="inPriceSummary" label="进项金额" width="120" :formatter="mFour" />
                    <el-table-column prop="outCountSummary" label="出项数量" width="120" :formatter="mCount" />
                    <el-table-column prop="outCostSummary" label="出项成本金额" width="120" :formatter="mFour" />
                    <el-table-column prop="outPriceSummary" label="出项金额" width="120" :formatter="mFour" />
                </el-table-column>
                <el-table-column prop="endCount" label="结存数量" width="120" :formatter="mCount" />
                <el-table-column prop="endCost" label="结存成本金额" width="120" :formatter="mFour" />
                <el-table-column prop="diffRate" label="成本差异率" width="120" />
                <el-table-column prop="endDiff" label="结存成本差异" width="120" :formatter="mFour" />
            </el-table>
            <ef-pagination @pageChange="pageChange" :total="page.total" :default-limit="settle.limit" />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import PickupReturnAudit from 'components/PickupReturnAudit';
import EfPagination from 'components/EfPagination';

export default {
    name: 'SettleDetail',
    props: ['form'],
    components: { PickupReturnAudit, EfPagination },
    data() {
        return {
            tableData: [],
            settle: {
                settleCode: this.form.code,
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
            },
            url: '/report/month/detail/page',
            baseUrl: '/report/monthSettle',
            emptyZero: true,
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.form.code, this.baseUrl);
        },
        mFour: Util.mFour,
        mCount: Util.mCount,
        handleQuery() {
            Util.queryTable(this, this.url, { params: this.settle });
        },
        pageChange(page, limit) {
            this.settle.page = page;
            this.settle.limit = limit;
            this.handleQuery();
        },
    },
};
</script>
